<template>
  <slot v-if="hasPermissionSync && hasPermissionForCurrentMeeting"></slot>
  <div v-else>
    <div class="flex justify-center items-center h-full">
      <sl-spinner style="font-size: 2rem" />
      Chargement du stage...
    </div>
  </div>
</template>
<script setup lang="ts">
const meetingId = useMeetingId();

const store = useUserStore();
const { hasPermissionSync, hasMeetingPermissionSync } = storeToRefs(store);

const hasPermissionForCurrentMeeting = computed(() => {
  const meetingIdValue = meetingId.value;
  if (!meetingIdValue) {
    return true;
  }
  return hasMeetingPermissionSync.value(meetingIdValue);
});

usePermission();
</script>
